import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { Dialog } from '@headlessui/react';

const navigation = [
  { name: 'Procédure France 🇫🇷', href: '/procedure-france' },
  { name: 'Procédure Canada 🇨🇦', href: '/procedure-canada' },
  { name: 'Procédure Belgique 🇧🇪', href: '/procedure-belgique' },
  { name: '', href: '#' },
];
export function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 md:px-40">
          <div className="flex lg:flex-1">
            <a href="/" className="-ml-7">
              <img src="/assets/edukenvol-logo.svg" width={200} height={200} alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button type="button" onClick={() => setMobileMenuOpen(true)}>
              <FaBars size={20} />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-xl font-bold hover:text-[#2196F3]">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="/teams" className="text-xl text-white font-bold bg-[#2196F3] px-4 py-2.5 rounded-md">
              Nous contacter
            </a>
          </div>
        </nav>
        {/* mobile navigation */}
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-4 sm:max-w-sm">
            <div className="flex items-center justify-between">
              <a href="/" className="-ml-7">
                <img src="/assets/edukenvol-logo.svg" width={200} height={200} alt="" />
              </a>
              <button type="button" className="" onClick={() => setMobileMenuOpen(false)}>
                <span className="sr-only">Close menu</span>
                <AiOutlineClose size={20} />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-6 py-6">
                  {navigation.map((item) => (
                    <a
                      href={item.href}
                      key={item.name}
                      className="-mx-3 block rounded-lg px-3 text-xl font-semibold leading-7 text-gray-900 hover:text-[#2196F3]"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
              <div>
                <a href="/teams" className="text-xl text-white font-bold bg-[#2196F3] px-4 py-2.5 rounded-md">
                  Nous contacter
                </a>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </div>
  );
}
