import React, { useEffect } from 'react';
import { TableCellCa } from './TableCells';
import { useAppContext } from '../Context';
import { IsLoaded } from '../components';
const typeProcedure = 2;

export function DashboardCa() {
  const { fetchAllDossier } = useAppContext();
  const [isLoaded, setIsLoaded] = React.useState(false);

  useEffect(() => {
    setIsLoaded(true);
    fetchAllDossier(typeProcedure);
    setIsLoaded(false);
  }, []);

  return <div className="w-full">{isLoaded ? <IsLoaded /> : <TableCellCa typeProcedure={typeProcedure} />}</div>;
}
