import TypeProcedureDisplayer from '../Admin/TypeProcedureDisplayer';
import StatutDossierDisplayer from '../Admin/StatutDossierDisplayer';
import { IoEyeSharp } from 'react-icons/io5';
import { SearchBar } from '../Admin/SearchBar';
import { SearchBayStatus } from '../Admin/SearchBayStatus';
import React from 'react';
import { useAppContext } from '../../Context';
import { Link } from 'react-router-dom';
import { TfiUser } from 'react-icons/tfi';

export function TableCellCa({ typeProcedure }) {
  const { dossiers } = useAppContext();
  return (
    <div className="px-4 sm:px-6 lg:px-8 pt-5">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Procédure campus Canada</h1>
          <p className="mt-2 text-sm text-gray-700">Liste des procédures Canada</p>
        </div>
        <div>
          <SearchBar typeProcedure={typeProcedure} />
        </div>
        <div>
          <SearchBayStatus typeProcedure={typeProcedure} />
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add user
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle border rounded-xl overflow-hidden">
            <table className="min-w-full divide-y divide-gray-300 bg-gray-100 rounded-xl">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                    Date
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Procédure
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Utilisateurs
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    Modifier
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {dossiers.map((dossier) => (
                  <tr key={dossier.email.toLowerCase()}>
                    <td className="whitespace-nowrap py-5 text-sm pl-4 pr-3 text-left">
                      <div className="flex items-center">
                        <div className="border-2 border-black rounded-full w-16 h-16 flex justify-center items-center">
                          <TfiUser size={30} />
                        </div>
                        <div className="ml-4">
                          <div className="font-medium capitalize text-gray-900">
                            {dossier.nom + ' ' + dossier.prenom}
                          </div>
                          <div className="mt-1 text-gray-500">{dossier.email}</div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <div className="text-gray-900">
                        <TypeProcedureDisplayer typeProcedure={dossier.typeProcedure} />
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{dossier.numeroDossier}</td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <StatutDossierDisplayer statutDossier={dossier.statut} />
                    </td>
                    <td className="text-center text-sm font-medium">
                      <Link to={`/user-details`} state={{ id: dossier.id }}>
                        <button className="text-indigo-600 hover:text-indigo-900 cursor-pointer">
                          <IoEyeSharp className="ml-20" size={20} />
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
