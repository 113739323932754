import React, { useEffect, useState } from 'react';
import { FetchDocumentComplementaireRequest, SubmitDocumentComplementaireRequest } from '../Services';
import { useLocation, Navigate } from 'react-router-dom';
import { IsLoaded } from './Admin/IsLoaded';
const DocumentComplementForm = () => {
  const [dossier, setDossier] = useState();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [formData, setFormData] = useState({ document: '' });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  useEffect(() => {
    const fetchCurrentDossier = async () => {
      const result = await FetchDocumentComplementaireRequest(id);
      console.log();
      if (result.length === 0 || result[0].IsValide === false) {
        setShouldRedirect(true);
        return;
      }
      setDossier(result[0]);
    };

    fetchCurrentDossier();
  }, [id]);

  if (shouldRedirect) {
    return <Navigate to="/" />;
  }

  const handleChangeFile = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleSubmit = async () => {
    console.log(formData);
    if (!formData.document || formData.document === '' || formData.document.size > 2000000) {
      alert('Le document est obligatoire et doit être inférieur à 2Mo.');
      return;
    }
    setIsLoaded(true);
    try {
      const res = await SubmitDocumentComplementaireRequest(id, formData.document);

      if (res.status === 200) {
        alert('Votre document a bien été envoyé !');
        setShouldRedirect(true);
      } else {
        alert("Une erreur est survenue lors de l'envoi du document.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoaded(false);
    }
  };

  return (
    <section className="bg-gray-50 ">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-xl xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-center  leading-tight tracking-tight text-gray-900">
              Edukenvol à besoin plus d'informations pour valider votre dossier !
            </h1>
            {isLoaded ? (
              <>
                <IsLoaded />
              </>
            ) : (
              <form>
                <div className="mb-6 flex flex-col justify-center items-center">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-900 dark:text-white">
                    Veuillez fournir un/une <strong className="text-red-500 capitalize">{dossier?.nomDocument}</strong>{' '}
                  </label>
                  <p className="text-xs">(format autorisé jpeg jpg ou pdf)</p>
                  <input
                    type="file"
                    onChange={(e) => {
                      handleChangeFile(e);
                    }}
                    name="document"
                    id="email"
                    accept="image/jpeg, image/jpg, application/pdf"
                    className="shadow-sm mt-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    required
                  />
                </div>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Envoyer
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export { DocumentComplementForm };
