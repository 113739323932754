import React from 'react';
import { MdOutlineLogout } from 'react-icons/md';
import { NavLink, Link } from 'react-router-dom';

export function Sidebar() {
  const activeLink = 'bg-gray-900 font-bold text-white px-3 py-2 rounded-md';

  return (
    <div className="p-6 w-1/2 h-screen bg-white z-20 fixed top-0 -left-96 lg:left-0 lg:w-60  peer-focus:left-0 peer:transition ease-out delay-150 duration-200 border-r-2 border-black">
      <div className="flex flex-col justify-start item-center">
        <h1 className="text-base text-center cursor-pointer font-bold text-blue-900 border-b border-gray-100 pb-4 w-full">
          Eduk Envol Admin
        </h1>
        <div className="my-4 border-b border-gray-400 pb-4">
          <div className="flex mb-2 justify-start items-center gap-4 rounded-md cursor-pointer m-auto">
            <NavLink
              to="/dashboard-france"
              className={({ isActive }) =>
                isActive ? activeLink : 'text-base text-gray-800 hover:bg-gray-500 px-3 py-2 rounded-md font-semibold'
              }
            >
              Procédure France
            </NavLink>
          </div>
          <div className="flex mb-2 justify-start items-center gap-4 rounded-md cursor-pointer m-auto">
            <NavLink
              to="/dashboard-belgique"
              className={({ isActive }) =>
                isActive ? activeLink : 'text-base text-gray-800 hover:bg-gray-500 px-3 py-2 rounded-md font-semibold'
              }
            >
              Procédure Belgique
            </NavLink>
          </div>
          <div className="flex mb-2 justify-start items-center gap-4 rounded-md cursor-pointer m-auto">
            <NavLink
              to="/dashboard-canada"
              className={({ isActive }) =>
                isActive ? activeLink : 'text-base text-gray-800 hover:bg-gray-500 px-3 py-2 rounded-md font-semibold'
              }
            >
              Procédure Canada
            </NavLink>
          </div>
        </div>
        <div className=" my-4">
          <div className="flex mb-2 justify-start items-center gap-4 pl-5 border border-gray-200  hover:bg-gray-900 p-2 rounded-md group cursor-pointer hover:shadow-lg m-auto">
            <MdOutlineLogout className="text-2xl text-gray-600 group-hover:text-white " />
            <h3 className="text-base text-gray-800 group-hover:text-white font-semibold ">
              <Link to="/admin/logout">Logout</Link>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
