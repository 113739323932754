import React, { useState } from 'react';
import { Layout } from '../components';
import { FormComponent } from '../components';

const Form = () => {
  const [formSelected, setFormSelected] = useState(null);
  const handleSelect = (e) => {
    setFormSelected(e.target.value);
  };

  return (
    <Layout>
      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-16 px-6 sm:py-24 sm:px-6 lg:px-8 my-24">
          <label htmlFor="underline_select" className="sr-only ">
            Quel la procédure que vous voulez commencer ?
          </label>
          <select
            onChange={handleSelect}
            id="underline_select"
            className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          >
            <option value={50}>Choisir une procédure</option>
            <option value={1}>Procédure France</option>
            <option value={2}>Procédure Canada</option>
            <option value={3}>Procédure Belgium</option>
          </select>
          <div className="mt-10">{formSelected && <FormComponent typeProcedure={formSelected} />}</div>
        </div>
      </div>
    </Layout>
  );
};

export default Form;
