import React from 'react';

const philosophie = [
  {
    title: 'NOTRE VISION',
    description:
      "Faciliter l'accès à une éducation supérieure de qualité pour les étudiants africains. Services d'accompagnement personnalisés vers les meilleures universités occidentales. Créer des opportunités d'apprentissage internationales pour les talents africains, contribuant au développement durable du continent.",
    imageUrl: '/assets/icons/icons8-vision-100.png',
  },
  {
    title: 'NOTRE MISSION',
    description:
      "Accompagner les étudiants africains dans leurs études à l'étranger. Services personnalisés et professionnels pour réussir leur candidature dans les universités de l'Occident. Nous veillons à fournir les meilleures ressources éducatives et un soutien adapté à chaque étudiant.",
    imageUrl: '/assets/icons/icons8-mission-100.png',
  },
  {
    title: 'NOTRE OBJECTIF',
    description:
      "Faciliter l'accès aux meilleures universités occidentales avec un accompagnement personnalisé tout au long du processus de candidature. Nous aspirons à créer un impact positif en ouvrant des opportunités aux étudiants africains talentueux et en contribuant au développement de l'éducation en Afrique.",
    imageUrl: '/assets/icons/icons8-goal-100.png',
  },
];

export function Philosophie() {
  return (
    <div className="pt-10 pb-20">
      <h2 className="text-3xl font-semibold text-center text-[#DA4167] leading-8 tracking-tight">Notre Philosophie</h2>
      <div className="flex md:flex-row flex-col mx-auto max-w-7xl lg:px-0 md:px-8 px-7 pt-10 md:space-x-8 md:space-y-0 space-y-8">
        {philosophie.map((item) => (
          <div className="flex flex-col justify-center">
            <div className="text-[#DA4167] mb-4 font-bold text-center">{item.title}</div>
            <div className="flex justify-center mb-4">
              <img src={item.imageUrl} width={60} height={60} alt="illustrations_icons" />
            </div>
            <p className="text-center">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
