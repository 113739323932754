import axios from 'axios';

const ApiUrl = process.env.REACT_APP_API_URL;

const CreateNewsletterCase = async (email) => {
  try {
    return await axios.post(
      `${ApiUrl}/newsletter`,
      {
        email: email,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export { CreateNewsletterCase };
