import React, { useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export function FranceHero() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="flex md:flex-row flex-col items-center justify-center gap-20 max-w-7xl mx-auto pt-32 md:px-0 px-6">
      <div>
        <p className="text-xl mb-4">
          Chaque année, l’Etat français consacre près de 20% de son budget national à l’éducation. Soit 10000 euros par
          étudiant, par an et ceci sans distinction de nationalité. L’excellence du système éducatif français n’est plus
          à démontrer. Il met à la disposition des étudiants en France, des centres de recherches et un grand réseau de
          plus d’une centaine d’universités.
        </p>
        <p className="text-xl">
          Notre agence vous accompagne dans le processus de votre voyage, qui vous fera vivre une belle expérience
          d’études supérieures.
        </p>
        <div className="pt-10">
          <button
            onClick={() => setIsModalOpen(true)}
            className="rounded-md bg-[#2196F3] px-3.5 py-2.5 text-xl font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Démarrer
          </button>
        </div>
        {isModalOpen ? (
          <>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                    <h3 className="text-md font-bold">Modalités de Paiement</h3>
                    <button
                      className="bg-transparent border-0 text-black float-right"
                      onClick={() => setIsModalOpen(false)}
                    >
                      <AiOutlineCloseCircle size={20} />
                    </button>
                  </div>
                  <div className="p-6 flex-auto">
                    <h3 className="md:w-96 w-64 mb-5">
                      Voici les modalités de paiement pour la phase d'admission de la procédure France.
                    </h3>
                    <div className="space-y-2">
                      <ul className="flex flex-col">
                        <span className="text-[#DA4167] font-semibold">Pour la France :</span>
                        <li>
                          <span className="font-semibold">Coût total :</span> 205 000 F CFA
                        </li>
                        <li>1ère tranche : 105 000 F CFA</li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Annuler
                    </button>
                    <a href="/formulaire">
                      <button
                        className="text-white bg-[#2196F3] font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        onClick={() => setIsModalOpen(false)}
                      >
                        J'accepte
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <img src="/assets/herobelgium.jpg" width={500} height={500} className="rounded-xl" alt="" />
    </div>
  );
}
