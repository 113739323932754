import React, { useEffect } from 'react';
import { BelgiumHero, BelgiumFaqs, BelgiumStep, Layout } from '../components';
import ReactGA from 'react-ga';

export function Belgique() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div>
      <Layout>
        <BelgiumHero />
        <BelgiumStep />
        <BelgiumFaqs />
      </Layout>
    </div>
  );
}
