import React from 'react';

export function FranceStep() {
  return (
    <div className="md:pt-20 pt-5">
      <div className="flex flex-col justify-center">
        <div>
          <h4 className="text-center text-[#DA4167] text-3xl font-bold mb-16">Comment procéder ?</h4>
        </div>
        <div className="flex justify-center">
          <div className="uppercase bg-[#2196F3] text-white text-3xl text-center rounded-xl font-bold py-2 w-44">
            France
          </div>
        </div>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-1 justify-center md:space-y-0 space-y-10 max-w-7xl mx-auto pt-6 lg:px-0 px-10">
        <div className="order-2 md:order-1">
          <div className="mb-4">
            <h2 className="text-[#2196F3] font-bold text-4xl mb-4 md:pt-0 pt-8">Etape 1</h2>
            <p className="font-bold text-2xl">Phase d'admission (Procédure pré-consulaire)</p>
          </div>
          <div>
            <div className="mb-8">
              <img src="/assets/border.svg" className="" alt="" />
            </div>
            <div className="md:mb-8">
              <ul className="list-disc text-[#DA4167] space-y-4">
                <li>
                  <span className="text-black text-lg">
                    Réunir les documents requis tels que les bulletins de notes de la classe de{' '}
                    <span className="font-bold">
                      2nde, 1ère et Terminale, relevé de notes et attestation/diplôme du Bac
                    </span>{' '}
                    (pour les titulaires d'un Bac+0),{' '}
                    <span className="font-bold">relevés de notes et attestation universitaire</span> (pour les étudiants
                    diplômés de Licence et Master).
                  </span>
                </li>
                <li>
                  <span className="text-black text-lg">
                    Présenter une carte d'identité nationale ou un passeport valide.
                  </span>
                </li>
                <li>
                  <span className="text-black text-lg">Fournir une photo d'identité et préparer un CV.</span>
                </li>
                <h4 className="font-bold text-lg">Accompagnement par le consultant</h4>
                <li>
                  <span className="text-black text-lg">Créer et gérer un compte "Etude en France".</span>
                </li>
                <li>
                  <span className="text-black text-lg">
                    Aider l'étudiant à choisir les universités et les programmes en fonction de son projet d'étude.
                  </span>
                </li>
                <li>
                  <span className="text-black text-lg">
                    Assister l'étudiant dans la rédaction des lettres de motivation et préparer l'entretien de
                    l'étudiant
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center space-y-4 order-1 md:order-2">
          <img src="/assets/france.svg" width={299} height={700} alt="" className="md:block hidden" />
          <img src="/assets/frmobile.svg" width={299} height={700} alt="" className="md:hidden block" />
        </div>
        <div className="order-3 flex flex-col space-y-6">
          <div>
            <div className="mb-4">
              <div className="flex flex-col space-y-4 mb-4">
                <h4 className="font-bold text-[#DA4167] text-lg">Frais et honoraires</h4>
                <ul className="list-disc text-[#DA4167] space-y-4">
                  <li>
                    <span className="text-black text-lg">
                      N'hésitez pas à planifier une rencontre avec un membre de notre équipe pour obtenir des détails
                      supplémentaires concernant nos frais de service.
                    </span>
                  </li>
                  <li>
                    <span className="text-black text-lg">
                      Une partie du montant est à régler avant le début de la procédure, et le reste une fois que
                      l'étudiant reçoit une acceptation de la part de ses choix d'universités.
                    </span>
                  </li>
                </ul>
              </div>
              <h2 className="text-[#2196F3] font-bold text-4xl mb-4">Etape 2</h2>
              <p className="font-bold text-2xl"> Procédure consulaire et assistance visa</p>
            </div>
            <div>
              <div className="mb-8">
                <img src="/assets/border.svg" className="" alt="" />
              </div>
              <div className="mb-8">
                <ul className="list-disc text-[#DA4167] space-y-4">
                  <h4 className="font-bold text-lg">Après l'admission:</h4>
                  <li>
                    <span className="text-black text-lg">L'étudiant passe à la procédure consulaire.</span>
                  </li>
                  <li>
                    <span className="text-black text-lg">
                      Un garant financier et un justificatif d'hébergement sont nécessaires (assistance fournie).
                    </span>
                  </li>
                  <li>
                    <span className="text-black text-lg">
                      Pour notre service d'assistance visa, une contribution initiale est demandée avant de commencer le
                      processus consulaire. Une fois le visa d'étude obtenu, le solde restant devient dû. Veuillez nous
                      contacter pour plus d'informations sur la structure précise de ces frais. Pour plus
                      d'informations, n'hésitez pas à vous mettre en relation avec l'un de nos consultants{' '}
                      <a className="text-[#2196F3] underline" href="/teams">
                        ici
                      </a>
                      .
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
