import React from 'react';
import { Layout } from '../components';

export function MentionsLegals() {
  return (
    <Layout>
      <div className="md:px-44 px-6 flex flex-col space-y-5 mb-5">
        <div className="pt-40">
          <h1 className="text-3xl font-bold text-gray-700 mb-10">Mentions légales</h1>
          <h2 className="text-2xl font-semibold mb-4">Le site internet</h2>
          <p>
            Le site internet <a href="/">www.edukenvol.com</a> appartient à
            <span className="font-bold"> CISSE CONSULTING GROUP</span>, une société à responsabilité limitée(SARL)
            enregistrée au RCCM de la République du Bénin sous le numéro RB/DGOU/2017-A-146 et dont le siège social est
            à Djougou (Bénin).
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-semibold mb-4">Contact commercial</h2>
          <a href="mailto:educenvoll@gmail.com">educenvoll@gmail.com</a>
        </div>
        <div>
          <h2 className="text-2xl font-semibold mb-4">Hébergement</h2>
          <p>
            Le site et les services web <a href="www.edukenvol.com">edukenvol.com</a> sont hébergés par la société :
            LIGNE WEB SERVICES (LWS) situé en France
          </p>
          <p>Le site web :https://lws.fr</p>
          <p>10 rue Penthièvre, 75008 PARIS</p>
        </div>
      </div>
    </Layout>
  );
}
