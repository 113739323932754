import React from 'react';
import { FranceFaqs, FranceHero, FranceStep, Layout } from '../components';

export function France() {
  return (
    <div>
      <Layout>
        <FranceHero />
        <FranceStep />
        <FranceFaqs />
      </Layout>
    </div>
  );
}
