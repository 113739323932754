import React from 'react';

const FormUrlDisplayer = ({ requestFormUrl }) => {
  console.log(requestFormUrl);

  const handleCopuePress = async () => {
    await navigator.clipboard.writeText(requestFormUrl);
    alert('Lien copié dans le presse-papier !');
  };

  return (
    <>
      <div className="flex flex-col mt-8 justify-center items-center space-y-8  ">
        <p className="flex text-center">
          Veuillez inviter l'étudiant à remplir le formulaire suivant :
          <a href={requestFormUrl} target="_blank" rel="noreferrer" className="text-blue-500">
            ici
          </a>
        </p>
        <div className="flex flex-row justify-center">
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 "
          >
            Envoyer par mail
          </button>

          <button
            onClick={handleCopuePress}
            type="button"
            className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 "
          >
            Cpier dans le presse-papier
          </button>
        </div>
      </div>
    </>
  );
};

export { FormUrlDisplayer };
