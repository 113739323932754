const navigation = {
  company: [
    { name: 'Procédure France 🇫🇷', href: '/procedure-france' },
    { name: 'Procédure Canada 🇨🇦', href: '/procedure-canada' },
    { name: 'Procédure Belgique 🇧🇪', href: '/procedure-belgique' },
    { name: 'Notre équipe', href: '/teams' },
  ],
  legal: [
    { name: 'Blog', href: '/blog' },
    { name: 'Mentions Légales', href: '/mentions-legales' },
    { name: 'Termes et Conditions', href: '/terms-conditions' },
  ],
  social: [
    {
      name: 'Edukenvol',
      href: 'https://www.facebook.com/edukenvol',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: '+32467730565',
      href: 'https://wa.me/32467730565',
      icon: (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-brand-whatsapp"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9"></path>
          <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1"></path>
        </svg>
      ),
    },
  ],
};

export function Footer() {
  return (
    <footer className="bg-gray-900 md:px-20 px-6" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="pb-8 pt-10 lg:px-8">
        <div className="flex md:flex-row flex-col justify-between">
          <div className="space-y-8">
            <a href="/">
              <img src="/assets/edukenvol-logo.svg" width={200} height={200} alt="" />
            </a>
            <p className="text-sm leading-6 text-gray-300 md:w-1/2">
              Edukenvol : Votre partenaire pour réussir vos études en France, Belgique et Canada. Nous accompagnons les
              étudiants dans leur parcours éducatif à l'étranger.
            </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-500 hover:text-gray-400 flex flex-row-reverse gap-2"
                >
                  <span className="text-white">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-16 xl:mt-0">
            <div className="flex flex-row gap-8">
              <div>
                <h3 className="text-md font-semibold leading-6 text-white">Company</h3>
                <ul className="mt-6 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-md leading-6 text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="">
                <h3 className="text-md font-semibold leading-6 text-white">Legal</h3>
                <ul className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-md leading-6 text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-white/10 pt-8">
          <p className="text-md text-center leading-5 text-gray-400">
            {new Date().getFullYear()} Edukenvol. Tous droits reservés.
          </p>
        </div>
      </div>
    </footer>
  );
}
