import React from 'react';

export function Statistics() {
  return (
    <div className="bg-[#111827] py-20">
      <div className="flex justify-center mb-10">
        <h3 className="text-3xl font-bold text-white md:w-1/3 text-center md:px-0 px-3">
          Nombre de personnes ayant suivit la procédure depuis 2020
        </h3>
      </div>
      <div className="">
        <div className="flex md:flex-row flex-col justify-center gap-8 md:px-0 px-4 max-w-5xl mx-auto">
          <div className="flex md:gap-8">
            <div>
              <div className="mb-4">
                <p className="text-[#DA4167] md:text-7xl text-4xl font-bold mb-4">2020</p>
                <img src="/assets/dateborder.svg" width={200} height={2} alt="border" />
              </div>
              <div className="flex flex-col space-y-4">
                <div className="flex items-center gap-3">
                  <div>
                    <img src="/assets/frflag.svg" width={40} height={40} alt="" />
                  </div>
                  <div>
                    <span className="text-white font-semibold">France</span>
                    <p className="text-white">
                      <span className="text-[#DA4167] font-bold text-xl">2 </span>
                      personnes
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div>
                    <img src="/assets/beflag.svg" width={40} height={40} alt="" />
                  </div>
                  <div>
                    <span className="text-white">Belgique</span>
                    <p className="text-white">
                      <span className="text-[#DA4167] font-bold text-xl">1 </span>
                      personne
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="mb-4">
                <p className="text-[#DA4167] md:text-7xl text-4xl font-bold mb-4">2021</p>
                <img src="/assets/dateborder.svg" width={200} height={2} alt="border" />
              </div>
              <div className="flex flex-col space-y-4">
                <div className="flex items-center gap-3">
                  <div>
                    <img src="/assets/frflag.svg" width={40} height={40} alt="" />
                  </div>
                  <div>
                    <span className="text-white font-semibold">France</span>
                    <p className="text-white">
                      <span className="text-[#DA4167] font-bold text-xl">12 </span>
                      personnes
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div>
                    <img src="/assets/beflag.svg" width={40} height={40} alt="" />
                  </div>
                  <div>
                    <span className="text-white font-semibold">Belgique</span>
                    <p className="text-white">
                      <span className="text-[#DA4167] font-bold text-xl">6 </span>
                      personnes
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex md:gap-8">
            <div className="md:pt-0 pt-6">
              <div className="mb-4">
                <p className="text-[#DA4167] md:text-7xl text-4xl font-bold mb-4">2022</p>
                <img src="/assets/dateborder.svg" width={200} height={2} alt="border" />
              </div>
              <div className="flex flex-col space-y-4">
                <div className="flex items-center gap-3">
                  <div>
                    <img src="/assets/frflag.svg" width={40} height={40} alt="" />
                  </div>
                  <div>
                    <span className="text-white font-semibold">France</span>
                    <p className="text-white">
                      <span className="text-[#DA4167] font-bold text-xl">15 </span>
                      personnes
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div>
                    <img src="/assets/beflag.svg" width={40} height={40} alt="" />
                  </div>
                  <div>
                    <span className="text-white font-semibold">Belgique</span>
                    <p className="text-white">
                      <span className="text-[#DA4167] font-bold text-xl">4 </span>
                      personnes
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:pt-0 pt-6">
              <div className="mb-4 flex flex-col space-y-4">
                <p className="text-[#DA4167] md:text-7xl text-4xl font-bold">2023</p>
                <div>
                  <img src="/assets/dateborder.svg" width={200} height={2} alt="border" />
                </div>
                <div className="flex items-center gap-3">
                  <div>
                    <img src="/assets/frflag.svg" width={40} height={40} alt="" />
                  </div>
                  <div>
                    <span className="text-white font-semibold">France</span>
                    <p className="text-white">
                      <span className="text-[#DA4167] font-bold text-xl">21 </span>
                      personnes
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
