import React, { useEffect, useState } from 'react';
import { TfiUser } from 'react-icons/tfi';
import { useLocation } from 'react-router-dom';
import { getOneDossier, updateDossier, FetchDossierTempRequest } from '../Services';
import TypeProcedureDisplayer from '../components/Admin/TypeProcedureDisplayer';
import StatutDossierDisplayer from '../components/Admin/StatutDossierDisplayer';
import { Modal } from '../components';

export function UserDetails() {
  const [statut, setStatut] = useState('');
  const [commentaire, setCommentaire] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [dossier, setDossier] = useState({});
  const [temprDossierRequest, setTemprDossierRequest] = useState({});
  const [isModal, setIsModal] = useState(false);
  const params = useLocation();
  const id = params?.state?.id;

  useEffect(() => {
    getOneDossier(id).then((res) => {
      setDossier(res);
    });
  }, [id, statut, commentaire]);

  useEffect(() => {
    FetchDossierTempRequest(id).then((res) => {
      setTemprDossierRequest(res);
    });
  }, [id]);

  const handleSubmit = async (e) => {
    setIsLoaded(true);
    e.preventDefault();
    if (statut === '') {
      alert('Veuillez choisir un statut pour le dossier.');
      setIsLoaded(false);
      return;
    }
    await updateDossier(id, statut, commentaire);
    getOneDossier(id).then((res) => {
      setDossier(res);
    });
    setIsLoaded(false);
  };

  return (
    <>
      {isLoaded ? (
        <div className="flex justify-center items-center h-96">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900">
            Veuillez patienter...
          </div>
        </div>
      ) : (
        <div className="">
          <h3 className="font-semibold text-xl mb-5 text-center  pt-10">
            Toutes les informations sur la procédure de l'étudiant se resume ici
          </h3>
          <div className="px-10">
            <div className="flex items-center gap-4 border-b border-gray-800 pb-4 mb-5">
              <div className="border-2 border-black rounded-full w-16 h-16 flex justify-center items-center">
                <TfiUser size={30} />
              </div>
              <div>
                <div className="font-medium text-gray-900">{dossier.nom + ' ' + dossier.prenom}</div>
                <div className="text-gray-500">{dossier.email}</div>
              </div>
            </div>
            <div className="mb-10">
              <div className="flex justify-between items-center border-b pb-5 mb-10">
                <span>Procédure details</span>
                <div>
                  <label form="status" className="block mb-2 text-sm font-medium text-gray-900">
                    Changer le status de la procédure
                  </label>
                  <select
                    onChange={(e) => {
                      setStatut(e.target.value);
                    }}
                    id="status"
                    className="bg-gray-50 border-2 text-gray-900 text-sm rounded-lg focus:ring-blue-500 border-blue-500 block p-2.5 w-44"
                  >
                    <option value="4">Choisir</option>
                    <option value="1">Nouveau</option>
                    <option value="2">En cours</option>
                    <option value="3">Complète</option>
                    <option value="4">Echoué</option>
                  </select>
                </div>
              </div>
              <div className="flex space-x-10">
                <div className="border border-black rounded-2xl p-4 w-full space-y-5">
                  <div className="w-full flex justify-between">
                    <span className="font-semibold">N°:</span>
                    <span className="text-gray-600">{dossier.NumeroDossier}</span>
                  </div>
                  <div className="w-full flex justify-between">
                    <span className="font-semibold">Email:</span>
                    <span className="text-gray-600">{dossier.email}</span>
                  </div>
                  <div className="w-full flex justify-between">
                    <span className="font-semibold">Numéro de téléphone:</span>
                    <span className="text-gray-600">{dossier.phone}</span>
                  </div>
                  <div className="w-full flex justify-between">
                    <span className="font-semibold">Type de procédure:</span>
                    <span className="text-gray-600">
                      <TypeProcedureDisplayer typeProcedure={dossier.typeProcedure} />
                    </span>
                  </div>
                  <div className="w-full flex justify-between">
                    <span className="font-semibold">Date:</span>
                    <span className="text-gray-600">{new Date(dossier.createdAt).toLocaleDateString()}</span>
                  </div>
                  <div className="w-full flex justify-between">
                    <span className="font-semibold">Status:</span>
                    <StatutDossierDisplayer statutDossier={dossier.statut} />
                  </div>
                  <div className="w-full flex justify-between">
                    <span className="font-semibold">Pays du candidat:</span>
                    <span className="text-gray-600 capitalize">{dossier.pays}</span>
                  </div>

                  {dossier.message && (
                    <div className="w-full">
                      <p className="block font-semibold mb-2  text-gray-900">Message de l'étudiant</p>
                      <div className="block bg-gray-100 rounded-xl p-2.5 w-full text-sm text-gray-900 ">
                        {dossier.message}
                      </div>
                    </div>
                  )}
                </div>
                <div className="border border-black rounded-2xl p-4 w-full space-y-5">
                  <div className="font-semibold">Pièces jointes</div>
                  <div className="w-full flex justify-between">
                    <span className="font-semibold">Bulletins/diplômes</span>
                    <a
                      href={`${process.env.REACT_APP_BASEURL}/Diplomes/${dossier.diplomes}`}
                      className="text-blue-600"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Télécharger
                    </a>
                  </div>
                  <div className="w-full flex justify-between">
                    <span className="font-semibold">CV</span>
                    <a
                      href={`${process.env.REACT_APP_BASEURL}/Cv/${dossier.cv}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600"
                    >
                      Télécharger
                    </a>
                  </div>
                  <div className="w-full flex justify-between">
                    <span className="font-semibold">Pièce d'identité</span>
                    <a
                      href={`${process.env.REACT_APP_BASEURL}/Passports/${dossier.passport}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600"
                    >
                      Télécharger
                    </a>
                  </div>
                  {dossier.autreDocument && (
                    <div className="w-full flex justify-between">
                      <span className="font-semibold">Photo d'identité</span>
                      <a
                        href={`${process.env.REACT_APP_BASEURL}/PhotoIdentite/${dossier.autreDocument}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600"
                      >
                        Télécharger
                      </a>
                    </div>
                  )}

                  <div className="w-full mt-2">
                    <p className="block font-semibold mb-1  text-gray-900">Document complémentaires :</p>
                    <div className="block p-2.5 w-full text-sm text-gray-900 ">
                      {dossier.documentComplementaire &&
                        dossier.documentComplementaire.map((doc) => (
                          <div key={doc.id} className="flex flex-row justify-between space-y-2">
                            <p className="font-semibold capitalize">{doc.nomDocument} : </p>
                            <a
                              href={`${process.env.REACT_APP_BASEURL}/documentComplementaire/${doc.chemin}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600"
                            >
                              Télécharger
                            </a>
                          </div>
                        ))}
                    </div>
                  </div>

                  <div className="w-full mt-2">
                    <p className="block font-semibold mb-1  text-gray-900">Demandes en attente :</p>
                    <div className="block p-2.5 w-full text-sm text-gray-900 "></div>
                  </div>
                </div>
              </div>

              <div className="flex flex-row space-x-4 my-3">
                <div className="basis-1/2">
                  <label form="message" className="block mb-2 text-sm font-medium text-gray-900">
                    Commentaire
                  </label>
                  <textarea
                    onChange={(e) => {
                      setCommentaire(e.target.value);
                    }}
                    id="message"
                    rows="3"
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                    placeholder="Write your thoughts here..."
                  ></textarea>
                </div>
                {dossier.commentaires && (
                  <div className="basis-1/2">
                    <p className="block mb-2 text-sm font-medium text-gray-900">Dernier commentaire :</p>
                    <div className="block my-3 p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg ">
                      {dossier.commentaires}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-center flex-row space-x-8">
              <button
                onClick={handleSubmit}
                className="bg-blue-700 px-10 py-2 rounded-md text-white text-xl"
                type="submit"
              >
                Mettre à jour
              </button>
              <button
                onClick={() => setIsModal(true)}
                className="bg-gray-500 px-5 py-2 rounded-md text-white text-xl"
                type="submit"
              >
                Demander un doc
              </button>
            </div>

            <Modal isModal={isModal} setIsModal={setIsModal} dossier={dossier} />
          </div>
        </div>
      )}
    </>
  );
}
