import { Routes, Route } from 'react-router-dom';
import {
  Belgique,
  Home,
  France,
  Canada,
  Teams,
  MentionsLegals,
  Blog,
  TermsConditions,
  Login,
  Logout,
  Layout,
  UserDetails,
} from './pages';
import { DashboardBe, DashboardCa, DashboardFr, DocumentComplementForm, NewProcedure } from './components';
import ReactGA from 'react-ga';
import Form from './pages/Form';

const TRACKING_ID = 'G-EFJLP25V72';
ReactGA.initialize(TRACKING_ID);

ReactGA.event({
  category: 'Button',
  action: 'Click',
  label: 'Submit',
});

export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/procedure-belgique" element={<Belgique />} />
        <Route path="/procedure-france" element={<France />} />
        <Route path="/procedure-canada" element={<Canada />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/mentions-legales" element={<MentionsLegals />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/formulaire" element={<Form />} />
        <Route path="/admin/12345azertyuip" element={<Login />} />
        <Route path="/admin/logout" element={<Logout />} />
        <Route path="/formulaire" element={<Form />} />
        <Route path="/user-complement" element={<DocumentComplementForm />} />
        <Route
          path="*"
          element={
            <Layout>
              <Routes>
                <Route path="/dashboard-france" element={<DashboardFr />} />
                <Route path="/dashboard-belgique" element={<DashboardBe />} />
                <Route path="/dashboard-canada" element={<DashboardCa />} />
                <Route path="/user-details" element={<UserDetails />} />
              </Routes>
            </Layout>
          }
        ></Route>
      </Routes>
    </div>
  );
}
