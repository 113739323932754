import React from 'react';
import { Sidebar } from '../components';
import { useAppContext } from '../Context';
import { Navigate } from 'react-router-dom';

export function Layout({ children }) {
  const { userToken } = useAppContext();
  // Si userToken n'existe pas, rediriger vers la page de connexion
  if (!userToken) {
    return <Navigate to="/admin/12345azertyuip" />;
  }

  return (
    <div className="flex items-center w-full p-4">
      <div>
        <Sidebar />
      </div>
      <div className="ml-[240px] w-full">{children}</div>
    </div>
  );
}
