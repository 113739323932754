import { Layout } from '../components';

export function TermsConditions() {
  return (
    <Layout>
      <div className="pt-40 md:px-44 px-6 pb-10">
        <h2>Terms and Conditions</h2>
        <div>Bienvenue sur Edukenvol !</div>
        <p>
          Les présentes conditions générales décrivent les règles et règlements relatifs à l'utilisation du site web de
          CISSE CONSULTING GROUP, situé à l'adresse www.edukenvol.com.
        </p>
        <p>
          En accédant à ce site web, nous supposons que vous acceptez ces conditions générales. Ne continuez pas à
          utiliser Edukenvol si vous n'acceptez pas de prendre toutes les conditions générales énoncées sur cette page.
        </p>
        <h2>Cookies :</h2>
        <p>
          Le site web utilise des cookies pour aider à personnaliser votre expérience en ligne. En accédant à Edukenvol,
          vous avez accepté d'utiliser les cookies requis.
        </p>
        <p>
          Un cookie est un fichier texte qui est placé sur votre disque dur par un serveur de pages web. Les cookies ne
          peuvent pas être utilisés pour exécuter des programmes ou transmettre des virus à votre ordinateur. Les
          cookies vous sont attribués de manière unique et ne peuvent être lus que par un serveur web du domaine qui
          vous a émis le cookie.
        </p>
        <p>
          Nous pouvons utiliser des cookies pour collecter, stocker et suivre des informations à des fins statistiques
          ou marketing pour exploiter notre site web. Vous avez la possibilité d'accepter ou de refuser les Cookies
          facultatifs. Il existe certains Cookies obligatoires qui sont nécessaires au fonctionnement de notre site web.
          Ces cookies ne nécessitent pas votre consentement car ils fonctionnent toujours. N'oubliez pas qu'en acceptant
          les Cookies obligatoires, vous acceptez également les Cookies tiers, qui pourraient être utilisés via des
          services fournis par des tiers si vous utilisez ces services sur notre site web, par exemple, une fenêtre
          d'affichage vidéo fournie par des tiers et intégrée à notre site web.
        </p>
        <h2>Licence :</h2>
        <p>
          Sauf indication contraire, CISSE CONSULTING GROUP et/ou ses concédants de licence détiennent les droits de
          propriété intellectuelle de l'ensemble du matériel présent sur Edukenvol. Tous les droits de propriété
          intellectuelle sont réservés. Vous pouvez y accéder à partir de Edukenvol pour votre usage personnel, sous
          réserve des restrictions fixées dans les présentes conditions générales.
        </p>
        <h3>Vous ne devez pas :</h3>
        <ul>
          <li>Copier ou republier des éléments provenant de Edukenvol.</li>
          <li>Vendre, louer ou concéder une sous-licence pour le matériel provenant de Edukenvol.</li>
          <li>Reproduire, dupliquer ou copier le matériel provenant de Edukenvol.</li>
          <li>Redistribuer le contenu de Edukenvol</li>
        </ul>
        <h3>Le présent accord prend effet à la date des présentes.</h3>
        <p>
          Certaines parties de ce site web offrent aux utilisateurs la possibilité de publier et d'échanger des opinions
          et des informations dans certaines zones du site web. CISSE CONSULTING GROUP ne filtre pas, n'édite pas, ne
          publie pas et ne révise pas les commentaires avant leur présence sur le site web. Les commentaires ne
          reflètent pas les vues et opinions de CISSE CONSULTING GROUP, de ses agents et/ou de ses affiliés. Les
          commentaires reflètent les vues et opinions de la personne qui les publie. Dans la mesure où les lois
          applicables le permettent, CISSE CONSULTING GROUP ne peut être tenu responsable des commentaires ou de toute
          responsabilité, de tout dommage ou de toute dépense causés et/ou subis à la suite de l'utilisation et/ou de
          l'affichage et/ou de l'apparition des commentaires sur ce site web.
        </p>
        <p>
          CISSE CONSULTING GROUP se réserve le droit de surveiller tous les commentaires et de supprimer tout
          commentaire qui peut être considéré comme inapproprié, offensant, ou qui entraîne une violation des présentes
          conditions générales.
        </p>
        <h3>Vous garantissez et déclarez que :</h3>
        <ul>
          <li>
            Vous avez le droit de publier les commentaires sur notre site web et disposez de toutes les licences et de
            tous les consentements nécessaires pour le faire ;
          </li>
          <li>
            Les commentaires n'envahissent aucun droit de propriété intellectuelle, y compris, sans limitation, le droit
            d'auteur, le brevet ou la marque de commerce d'un tiers ;
          </li>
          <li>
            Les Commentaires ne contiennent aucun élément diffamatoire, calomnieux, offensant, indécent ou autrement
            illégal, constituant une atteinte à la vie privée.
          </li>
          <li>
            Les Commentaires ne seront pas utilisés pour solliciter ou promouvoir des affaires ou des coutumes ou
            présenter des activités commerciales ou des activités illégales.
          </li>
        </ul>
        <p>
          Par la présente, vous accordez à CISSE CONSULTING GROUP une licence non exclusive pour utiliser, reproduire,
          éditer et autoriser d'autres personnes à utiliser, reproduire et éditer n'importe lequel de vos commentaires
          sous toutes les formes, formats ou médias.
        </p>
      </div>
    </Layout>
  );
}
