import React from 'react';
import { LoginComponent } from '../../components';
import { Navigate } from 'react-router-dom';
import { useAppContext } from '../../Context';

const Login = () => {
  const { userToken } = useAppContext();
  if (userToken) {
    return <Navigate to="/admin/dashbord" />; // Redirect to a new path
  }

  return (
    <>
      <LoginComponent />
    </>
  );
};

export { Login };
