import React from 'react';
import { CanadaFaqs, CanadaHero, CanadaStep, Layout } from '../components';

export function Canada() {
  return (
    <div>
      <Layout>
        <CanadaHero />
        <CanadaStep />
        <CanadaFaqs />
      </Layout>
    </div>
  );
}
