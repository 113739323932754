import React, { useState } from 'react';
import FormValidator from '../Services/formValidator';
import { CreateNewCase } from '../Services/CreateNewCase';
const FormComponent = ({ typeProcedure }) => {
  const [helper, setHelper] = useState(true);
  const [formData, setFormData] = useState({
    email: '',
    nom: '',
    prenom: '',
    phone: '',
    pays: '',
    diplomes: '',
    cv: '',
    passport: '',
    autre: '',
    message: '',
    typeProcedure: typeProcedure,
  });
  const [errors, setErrors] = useState({});
  const [apiResponse, setApiResponse] = useState(null);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeFile = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validation = FormValidator(formData);
    if (validation.isValid) {
      if (formData) {
        const response = await CreateNewCase(formData);
        setApiResponse(response);
        setHelper(false);
        setErrors({});
        setFormData({
          email: '',
          nom: '',
          prenom: '',
          phone: '',
          pays: '',
          diplomes: '',
          cv: '',
          passport: '',
          autre: '',
          message: '',
          typeProcedure: typeProcedure,
        });
      } else {
        console.error('formData is undefined or null');
      }
    }
    setErrors(validation.errors);
    setHelper(false);
  };

  return (
    <>
      {apiResponse ? ( // L'affiche du message de succès ou d'erreur après la soumission du formulaire
        <div className="">
          <div className="mx-auto  max-w-7xl py-16 px-6 sm:py-24 sm:px-6 lg:px-8 my-24">
            <div className="bg-gray-100 shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg text-center w-full leading-6 font-medium text-gray-900">
                  {apiResponse.data.message + ''}
                  {apiResponse.data?.NumeroDossier && (
                    <span className="text-blue-500">
                      {' '}
                      Votre numéro de dossier est : <strong>{' ' + apiResponse.data?.NumeroDossier}</strong>{' '}
                    </span>
                  )}
                </h3>
                <div className="flex  px-2 py-2  justify-center items-center">
                  <p className=" flex justify-center items-center ">
                    Un email avec vos informations de suivi de votre dossier vous a été envoyé à l'adresse, si vous ne
                    le trouvez pas, veuillez vérifier dans vos spams.
                  </p>
                </div>
                <div className="flex  px-2 py-2  justify-center items-center">
                  <a href="/" className="text-blue-500 flex justify-center items-center ">
                    Retour à la page d'accueil
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // L'affichage du formulaire
        <form onSubmit={handleSubmit} method="post">
          <div className="relative z-0 w-full mb-6 group">
            <input
              type="email"
              required={true}
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              htmlFor="floating_email"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Email{' '}
            </label>
            <p className="text-xs text-red-500">{errors.email && errors.email}</p>
          </div>

          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="nom"
                value={formData.nom}
                onChange={handleChange}
                id="nom"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="floating_first_name"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Nom
              </label>
              <p className="text-xs text-red-500">{errors.nom && errors.nom}</p>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="prenom"
                id="prenom"
                value={formData.prenom}
                onChange={handleChange}
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="floating_last_name"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Prénom
              </label>
              <p className="text-xs text-red-500">{errors.prenom && errors.prenom}</p>
            </div>
          </div>

          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="tel"
                name="phone"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="floating_phone"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Numéro de téléphone (0022960606060)
              </label>
              <p className="text-xs text-red-500">{errors.phone && errors.phone}</p>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="pays"
                id="pays"
                value={formData.pays}
                onChange={handleChange}
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="floating_company"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Votre Pays de résidence actuel
              </label>
            </div>
          </div>

          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-6 group">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" form="file_input">
                Diplômes et Relevé de notes (fusionnés en pdf){' '}
                <span className="text-2xl text-red-500">
                  <button onClick={() => setHelper(!helper)}>?</button>
                </span>
              </label>
              <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="file_input"
                type="file"
                name="diplomes"
                onChange={handleChangeFile}
                required={true}
                accept="application/pdf"
              />

              <p className="text-xs text-red-500">{errors.diplomes && errors.diplomes}</p>

              {helper ? (
                <p className="text-xs text-blue-500 w-full px-3 py-1 text-center flex justify-center items-center">
                  {parseInt(typeProcedure) === 1
                    ? 'Relevés de notes 2nd, 1ère et Terminale pour tillulaire de BAC+0 ou Relevé de notes, attestation et/ diplômes pour les étudiants diplomé(e)s de Licence et/ou Master.'
                    : 'Relevé de notes, attestation et/ diplômes pour les étudiants diplomé(e)s de Licence et/ou Master.'}
                </p>
              ) : null}
            </div>

            <div className="relative z-0 w-full mb-6 group">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" form="cv">
                CV{' '}
                <span className="text-2xl text-red-500">
                  <button onClick={() => setHelper(!helper)}>?</button>
                </span>
              </label>
              <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="cv"
                name="cv"
                type="file"
                onChange={handleChangeFile}
                required={true}
                accept="application/pdf"
              />
              <p className="text-xs text-red-500">{errors.cv && errors.cv}</p>

              {helper ? (
                <p className="text-xs text-blue-500 w-full px-3 py-1 text-center flex justify-center items-center">
                  Veuillez renseigné votre CV actualisé sous format pdf 2,5 M taille de fichier maximale
                </p>
              ) : null}
            </div>
          </div>

          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-6 group">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" form="file_input">
                Pièce d'identité valide{' '}
                <span className="text-2xl text-red-500">
                  <button onClick={() => setHelper(!helper)}>?</button>
                </span>
              </label>
              <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="file_input"
                name="passport"
                type="file"
                onChange={handleChangeFile}
                required={true}
                accept="application/pdf"
              />
              <p className="text-xs text-red-500">{errors.passport && errors.passport}</p>

              {helper ? (
                <p className="text-xs text-blue-500 w-full px-3 py-1 text-center flex justify-center items-center">
                  Veuillez renseigner une pièce d'identité valide sous format pdf 2,5 M taille de fichier maximale
                </p>
              ) : null}
            </div>

            <div className="relative z-0 w-full mb-6 group">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" form="file_input">
                Photo d'identité (jpeg ou jpg){' '}
                <span className="text-2xl text-red-500">
                  <button onClick={() => setHelper(!helper)}>?</button>
                </span>
              </label>
              <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="file_input"
                name="autre"
                type="file"
                onChange={handleChangeFile}
                required={true}
                accept="image/jpeg, image/jpg"
              />
              <p className="text-xs text-red-500">{errors.autre && errors.autre}</p>
              {helper ? (
                <p className="text-xs text-blue-500 w-full px-3 py-1 text-center flex justify-center items-center">
                  Veuillez renseigner une d'identité (format jpeg ou jpg sont acceptés)
                </p>
              ) : null}
            </div>
          </div>

          <div className="relative z-0 w-full mb-6 group">
            <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Un commentaire du votre dossier ?
            </label>
            <textarea
              id="message"
              rows="4"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              name="message"
              onChange={handleChange}
              placeholder="Si vous avez un commentaire en particulier veuillez écrire ici..."
            ></textarea>
          </div>

          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Envoyer mon dossier{' '}
          </button>
        </form>
      )}
    </>
  );
};

export { FormComponent };
