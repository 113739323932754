import { useState } from 'react';
import { CreateNewsletterCase } from '../Services/Newsletter';
import { IsLoaded } from './Admin/IsLoaded';

export function Newsletter() {
  const [email, setEmail] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      alert("L'email est obligatoire !");
      return;
    }
    try {
      setIsLoaded(true);
      const res = await CreateNewsletterCase(email);
      if (res.status === 200) {
        setResponse(res.data);
        setSuccess(true);
        setIsLoaded(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoaded(false);
    } finally {
      setIsLoaded(false);
    }
  };

  return (
    <>
      {isLoaded ? (
        <IsLoaded />
      ) : (
        <div className="bg-white py-16 sm:py-24 md:p-0 px-2 md:mb-20 md:mt-10">
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="relative isolate flex flex-col gap-10 overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl rounded-3xl sm:px-24 xl:flex-row xl:items-center xl:py-32">
              <h2 className="max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl xl:max-w-none xl:flex-auto">
                Soyez informé de nos actualités.
              </h2>
              {success ? (
                <div className="flex flex-col gap-4">
                  <p className="text-white text-lg">{response.message}</p>
                </div>
              ) : (
                <form className="w-full max-w-md">
                  <div className="flex gap-x-4">
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="email-address"
                      name="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      type="email"
                      autoComplete="email"
                      required
                      className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                      placeholder="Entrer votre email"
                    />
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                    >
                      S'abonner
                    </button>
                  </div>
                  <p className="mt-4 text-sm leading-6 text-gray-300">
                    Nous nous soucions de vos données. Lisez notre{' '}
                    <a href="/" className="font-semibold text-white">
                      politique de confidentialité.
                    </a>
                  </p>
                </form>
              )}
              <svg
                viewBox="0 0 1024 1024"
                className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
                aria-hidden="true"
              >
                <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                <defs>
                  <radialGradient
                    id="759c1415-0410-454c-8f7c-9a820de03641"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(512 512) rotate(90) scale(512)"
                  >
                    <stop stopColor="#7775D6" />
                    <stop offset={1} stopColor="#E935C1" stopOpacity={0} />
                  </radialGradient>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
