import React from 'react';
import { Hero, Philosophie, Newsletter, Testimonials, Statistics, Layout } from '../components';

export function Home() {
  return (
    <div>
      <Layout>
        <Hero />
        <Statistics />
        <Philosophie />
        <Testimonials />
        <Newsletter />
      </Layout>
    </div>
  );
}
