import React from 'react';
const StatutDossierDisplayer = ({ statutDossier }) => {
  switch (parseInt(statutDossier)) {
    case 1:
      return (
        <span className="inline-flex items-center rounded-xl bg-red-100 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
          Nouveau
        </span>
      );
    case 2:
      return (
        <span className="inline-flex items-center rounded-xl bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
          En cours
        </span>
      );
    case 3:
      return (
        <span className="inline-flex items-center rounded-xl bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
          Complète
        </span>
      );
    case 4:
      return (
        <span className="inline-flex items-center rounded-xl bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
          Échec
        </span>
      );
    default:
      return (
        <span className="inline-flex items-center rounded-xl bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
          En cours
        </span>
      );
  }
};

export default StatutDossierDisplayer;
