import { Layout } from '../components';
import { AiOutlineMail, AiFillLinkedin } from 'react-icons/ai';

const people = [
  {
    name: 'Abdou-Rahaman CISSE',
    role: 'CEO CISSE CONSULTING GROUP',
    imageUrl: '/assets/pictures/team1.jpeg',
    mailIcons: 'mailto:admin@edukenvol.com',
    linkedinUrl: 'https://www.linkedin.com/in/cisse-abdu-rahaman-56091bb4/',
  },
  {
    name: 'Zoulfikarou SEIBOU',
    role: 'Coordinateur de la  Documentation',
    imageUrl: '/assets/pictures/team4.jpg',
    mailIcons: 'mailto:admin@edukenvol.com',
    linkedinUrl: 'https://www.linkedin.com/in/zoulfikarou-seibou-663429288/',
  },
  {
    name: 'Nazif TOURE',
    role: 'Responsable technique',
    imageUrl: '/assets/pictures/team2.jpeg',
    mailIcons: 'mailto:admin@edukenvol.com',
    linkedinUrl: 'https://www.linkedin.com/in/nazif-toure-21a922194/',
  },
  {
    name: 'Rodiath NALLA',
    role: "Consultante chargée à l'organisation des entretiens",
    imageUrl: '/assets/pictures/woman.png',
    mailIcons: 'mailto:admin@edukenvol.com',
    linkedinUrl: 'https://www.linkedin.com',
  },
];
export function Teams() {
  return (
    <Layout>
      <div className="py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 text-center lg:px-8">
          <div className="mx-auto max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Rencontrer notre équipe</h2>
            <p className="mt-4 text-lg leading-8 text-gray-400">
              Nous sommes un groupe dynamique de personnes passionnées par ce que nous faisons.
            </p>
          </div>
          <ul className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-6 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4 lg:gap-8">
            {people.map((person) => (
              <li key={person.name} className="rounded-2xl bg-gray-800 px-8 py-10 flex flex-col justify-between">
                <div>
                  <img
                    className="mx-auto h-48 w-48 rounded-full md:h-56 md:w-56 shadow-lg shadow-blue-600 object-contain"
                    src={person.imageUrl}
                    alt=""
                  />
                </div>
                <div>
                  <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-white">{person.name}</h3>
                  <p className="text-sm leading-6 text-gray-400">{person.role}</p>
                </div>
                <div>
                  <ul className="mt-6 flex justify-center gap-x-6">
                    <li className="text-white hover:text-gray-300">
                      <a href={person.mailIcons}>
                        <AiOutlineMail size={20} />
                      </a>
                    </li>
                    <li className="text-white hover:text-gray-300">
                      <a href={person.linkedinUrl}>
                        <AiFillLinkedin size={20} />
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
}
