import { Fragment, useState } from 'react';
import { Accordion, AccordionHeader, AccordionBody } from '@material-tailwind/react';

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${id === open ? 'rotate-180' : ''} h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}
export function BelgiumFaqs() {
  const [open, setOpen] = useState(0);
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="pt-10 md:px-0 px-6 mb-20">
      <h3 className="text-center text-4xl text-[#DA4167] font-bold mb-6">FAQs</h3>
      <div className="max-w-4xl mx-auto p-5 border border-sky-600 rounded-xl">
        <Fragment>
          <div className="flex flex-col space-y-5">
            <div>
              <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                <AccordionHeader onClick={() => handleOpen(1)}>
                  Comment faire pour s'inscrire dans une école en Belgique ?
                </AccordionHeader>
                <hr />
                <AccordionBody>
                  <p className="text-lg">
                    Chaque établissement en Belgique possède un service des admissions et des inscriptions. Il est donc
                    conseillé de contacter directement ce service après avoir trouver leurs coordonnées sur le site
                    internet de l'établissement, afin de connaître exactement les documents à fournir dans votre dossier
                    d'inscription.
                  </p>
                </AccordionBody>
              </Accordion>
            </div>
            <div>
              <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                <AccordionHeader onClick={() => handleOpen(2)}>Quel âge pour étudier en Belgique ?</AccordionHeader>
                <hr />
                <AccordionBody>
                  <p className="text-lg">
                    Pour commencer, il faut savoir qu'il n'existe pas d'âge limite officiel pour venir poursuivre les
                    études en Belgique. Néanmoins en fonction de votre passé académique et de votre expérience
                    professionnelle, il est possible que certains aspects vous soient défavorable pour votre projet
                    d'étude en Belgique
                  </p>
                </AccordionBody>
              </Accordion>
            </div>
          </div>
        </Fragment>
      </div>
    </div>
  );
}
