import React from 'react';

export function CanadaStep() {
  return (
    <div className="md:pt-20 pt-5">
      <div className="flex flex-col justify-center">
        <div>
          <h4 className="text-center text-[#DA4167] text-3xl font-bold mb-16">Comment procéder ?</h4>
        </div>
        <div className="flex justify-center">
          <div className="uppercase bg-[#2196F3] text-white text-3xl text-center rounded-xl font-bold py-2 w-44">
            Canada
          </div>
        </div>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-1 justify-center md:space-y-0 space-y-10 max-w-7xl mx-auto pt-6 lg:px-0 px-10">
        <div className="order-2 md:order-1">
          <div className="flex flex-col">
            <div>
              <div className="mb-4">
                <h2 className="text-[#2196F3] font-bold text-4xl mb-4 md:pt-0 pt-8">Etape 1</h2>
                <p className="font-bold text-2xl">Phase d'admission</p>
              </div>
              <div>
                <div className="mb-8">
                  <img src="/assets/border.svg" className="" alt="" />
                </div>
                <div className="mb-8">
                  <ul className="list-disc text-[#DA4167] space-y-5">
                    <h4 className="text-[#DA4167]  font-bold">Préparation du dossier:</h4>
                    <li>
                      <span className="text-black text-lg">
                        Collecte des documents requis, tels que les bulletins du secondaire, le relevé de notes du bac
                        et l'attestation, ainsi que le relevé de notes de l'université et l'attestation ou le diplôme si
                        applicable.
                      </span>
                    </li>
                    <li>
                      <span className="text-black text-lg">
                        Les frais d'admission dépendent de l'université choisie.
                      </span>
                    </li>
                    <h4 className="text-[#DA4167] font-bold">Frais et honoraires:</h4>
                    <p className="text-black text-lg">
                      N'hésitez pas à planifier une rencontre avec un membre de notre équipe pour obtenir des détails
                      supplémentaires concernant nos frais de service.
                    </p>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <div className="mb-4">
                <h2 className="text-[#2196F3] font-bold text-4xl mb-4">Etape 2</h2>
                <p className="font-bold text-2xl">Demande de CAQ (Certificat d'Acceptation au Québec)</p>
              </div>
              <div>
                <div className="mb-8">
                  <img src="/assets/border.svg" className="" alt="" />
                </div>
                <div className="md:mb-8">
                  <ul className="list-disc text-[#DA4167] space-y-4">
                    <h4 className="text-[#DA4167]  font-bold">Si l'admission est obtenue au Québec :</h4>
                    <li>
                      <span className="text-black text-lg">Une demande de CAQ doit être effectuée.</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center space-y-4 order-1 md:order-2">
          <img src="/assets/canada.svg" width={299} height={700} alt="" className="md:block hidden" />
          <img src="/assets/canadamobile.svg" width={299} height={700} alt="" className="md:hidden block" />
        </div>
        <div className="order-3 flex flex-col space-y-6">
          <div>
            <ul className="list-disc text-[#DA4167] mb-4">
              <li>
                <span className="text-black text-lg">
                  Les frais pour la demande de CAQ s'élèvent à environ 130 dollars canadiens.
                </span>
              </li>
            </ul>
            <div className="mb-4">
              <h2 className="text-[#2196F3] font-bold text-4xl mb-4">Etape 3</h2>
              <p className="font-bold text-2xl">Demande de permis d'étude</p>
            </div>
            <div>
              <div className="mb-8">
                <img src="/assets/border.svg" className="" alt="" />
              </div>
              <div className="mb-4">
                <ul className="list-disc text-[#DA4167] space-y-5">
                  <h4 className="text-[#DA4167]  font-bold">Préparation de la demande de permis d'étude :</h4>
                  <li>
                    <span className="text-black text-lg">
                      Les frais pour la demande de permis d'étude sont d'environ 250 dollars canadiens.
                    </span>
                  </li>
                  <li>
                    <span className="text-black text-lg">
                      Un garant financier est requis pour la demande de permis d'étude.
                    </span>
                  </li>
                  <li>
                    <span className="text-black text-lg">
                      Le garant doit fournir une attestation de solde accompagnée des six derniers relevés de compte.
                    </span>
                  </li>
                </ul>
              </div>
              <div>
                <p className="text-lg">
                  Pour la demande de permis d’étude, un garant financier est requis. En effet, le garant doit réunir au
                  retrait des documents l’attestation de solde accompagné de six derniers relevés de compte (Pour plus
                  d’information) prend contact avec l’un des consultants{' '}
                  <a className="text-[#2196F3] underline" href="/teams">
                    ici
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
