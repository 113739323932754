const validateUser = (user) => {
  let formErrors = {};

  // simple email validation
  if (!user.email || !/\S+@\S+\.\S+/.test(user.email)) {
    formErrors.email = 'Adresse email invalide';
  }

  if (!user.prenom) {
    formErrors.prenom = 'Vous devez renseigner votre prénom';
  }

  if (!user.nom) {
    formErrors.nom = 'Vous devez renseigner votre nom';
  }

  //  phone validation
  if (!user.phone || user.phone.length < 8 || !/^\d+$/.test(user.phone)) {
    formErrors.phone = 'Phone number is not valid';
  }

  //vérifier si le ficher est pdf et sa taille est inférieur à 2Mo
  if (!user.cv || user.cv.type !== 'application/pdf' || user.cv.size > 2000000) {
    formErrors.cv = 'Format de fichier invalide ou taille supérieur à 2Mo';
  }

  //vérifier si le ficher est pdf et sa taille est inférieur à 2Mo
  if (!user.passport || user.passport.type !== 'application/pdf' || user.passport.size > 2000000) {
    formErrors.passport = 'Format de fichier invalide ou taille supérieur à 2Mo';
  }

  //vérifier si le ficher est pdf et sa taille est inférieur à 2Mo
  if (!user.diplomes || user.diplomes.type !== 'application/pdf' || user.diplomes.size > 5000000) {
    formErrors.diplomes = 'Format de fichier invalide ou taille supérieur à 5Mo';
  }

  //vérifier si le ficher est jpeg ou jpg et sa taille est inférieur à 1Mo
  if (
    !user.autre ||
    (user.autre.type.toLowerCase() !== 'image/jpeg' && user.autre.type.toLowerCase() !== 'image/jpg') ||
    user.autre.size > 1000000
  ) {
    formErrors.photo = 'La photo doit être au format jpeg ou jpg et sa taille doit être inférieur à 1Mo';
  }

  // if errors object is empty, form is valid
  return { isValid: Object.keys(formErrors).length === 0, errors: formErrors };
};

export default validateUser;
