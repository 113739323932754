import React from 'react';

export function BelgiumStep() {
  return (
    <div className="md:pt-20 pt-5">
      <div className="flex flex-col justify-center">
        <div>
          <h4 className="text-center text-[#DA4167] text-3xl font-bold mb-16">Comment procéder ?</h4>
        </div>
        <div className="flex justify-center">
          <div className="uppercase bg-[#2196F3] text-white text-3xl text-center rounded-xl font-bold py-2 w-44">
            Belgique
          </div>
        </div>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-1 justify-center md:space-y-0 space-y-10 max-w-7xl mx-auto pt-6 lg:px-0 px-10">
        <div className="order-2 md:order-1">
          <div className="mb-4">
            <h2 className="text-[#2196F3] font-bold text-4xl mb-4 md:pt-0 pt-8">Etape 1</h2>
            <p className="font-bold text-2xl">Admission en Licence/Bachelier</p>
          </div>
          <div>
            <div className="mb-8">
              <img src="/assets/border.svg" className="" alt="" />
            </div>
            <div className="mb-8">
              <ul className="list-disc text-[#DA4167] space-y-5">
                <span className="text-black text-lg">
                  Prérequis académiques pour les étudiants sans passé académique (Bac uniquement).
                </span>
                <li className="mb-4">
                  <span className="text-black text-lg">
                    Obtention d'une moyenne supérieure ou égale à{' '}
                    <span className="font-bold">13/20 (Université Publique)</span> ou{' '}
                    <span className="font-bold">12/20 (Haute École) au Bac</span>.
                  </span>
                </li>
                <p className="text-black text-lg">Prérequis académiques pour les étudiants avec passé académique:</p>
                <li>
                  <span className="text-black text-lg">
                    Acquisition d'au moins <span className="font-bold">75 % des crédits</span> du programme annuel
                    d'études pour chaque année d'études supérieures.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center space-y-4 order-1 md:order-2">
          <img src="/assets/belgique-flag.svg" width={299} height={700} alt="" className="md:block hidden" />
          <img src="/assets/belgiquemobile.svg" width={299} height={700} alt="" className="md:hidden block" />
        </div>
        <div className="order-3 flex flex-col space-y-6">
          <div>
            <div className="mb-4">
              <h2 className="text-[#2196F3] font-bold text-4xl mb-4">Etape 2</h2>
              <p className="font-bold text-2xl">Frais d'admission</p>
            </div>
            <div>
              <div className="mb-8">
                <img src="/assets/border.svg" className="" alt="" />
              </div>
              <div className="mb-8">
                <ul className="list-disc text-[#DA4167]">
                  <li>
                    <span className="text-black text-lg">
                      L'admission à une université belge nécessite une procédure d'inscription, discuter avec un membre
                      de notre équipe pour en savoir plus sur les frais d'admissions.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-4">
              <h2 className="text-[#2196F3] font-bold text-4xl mb-4">Etape 3</h2>
              <p className="font-bold text-2xl">Procédure consulaire et assistance visa</p>
            </div>
            <div>
              <div className="mb-8">
                <img src="/assets/border.svg" className="" alt="" />
              </div>
              <div className="mb-8">
                <ul className="list-disc text-[#DA4167] space-y-5">
                  <div className="text-black text-lg">
                    <span>Une fois l'étudiant admis, il passe à la procédure consulaire qui comprend :</span>
                  </div>
                  <li>
                    <span className="text-black text-lg">
                      Recherche d'un garant financier et d'un justificatif d'hébergement (assistance fournie).
                    </span>
                  </li>
                  <li>
                    <span className="text-black text-lg">
                      Pour notre service d'assistance consulaire et assistance visa, une contribution initiale est
                      demandée avant de commencer le processus consulaire. Une fois le visa d'étude obtenu, le solde
                      restant devient dû. Veuillez nous contacter pour plus d'informations sur la structure précise de
                      ces frais. Pour plus d'informations, n'hésitez pas à vous mettre en relation avec l'un de nos
                      consultants{' '}
                      <a className="text-[#2196F3] underline" href="/teams">
                        ici
                      </a>
                      .
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
