import React, { useEffect, useState } from 'react';
import { useAppContext } from '../Context';
import { useNavigate } from 'react-router-dom';
import { IsLoaded } from './Admin/IsLoaded';
import { CreateDocumentComplementaireRequest } from '../Services';
import { FormUrlDisplayer } from './Admin/FormUrlDisplayer';

const Modal = ({ isModal, setIsModal, dossier }) => {
  const [document, setDocument] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [success, setSuccess] = useState(false);
  const [requestId, setRequestId] = useState();
  const [requestFormUrl, setRequestFormUrl] = useState();
  const { userToken } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userToken && isModal) {
      setIsModal(false);
      navigate('/admin/12345azertyuip');
    }
  }, [isModal, dossier, userToken, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!document) {
      alert('Le nom du document est obligatoire !');
      return;
    }

    if (!dossier || !dossier.id) {
      alert('Le dossier ou son id est indéfini.');
      return;
    }

    try {
      setIsLoaded(true);
      const res = await CreateDocumentComplementaireRequest(dossier.id, document);
      if (res.status === 200) {
        setSuccess(true);
        if (res.data.requestId) {
          setRequestId(res.data.requestId);
          setRequestFormUrl(`${process.env.REACT_APP_URL}/user-complement?id=${res.data.requestId}`);
        }
      } else {
        console.log('Erreur lors de la création de la demande de document complémentaire.');
      }
    } catch (error) {
      console.log(error);
      setIsLoaded(false);
    }
  };

  const handleCloseModal = () => {
    setIsModal(false);
    setSuccess(false);
    setIsLoaded(false);
  };

  return (
    <>
      {isModal && (
        <>
          <div className="fixed bg-gray-50  top-0 left-0 right-0 bottom-0 z-50  w-full h-full  p-4 overflow-x-hidden overflow-y-auto  flex items-center  justify-center  md:inset-0">
            <div className="relative w-full max-w-2xl max-h-full">
              <div className="relative bg-white rounded-lg shadow ">
                <div className="flex items-center justify-between p-4 border-b rounded-t ">
                  <h3 className="text-xl text-center font-semibold text-gray-900 ">
                    Demander un document complémentaire à{' '}
                    <strong className="text-blue-500">{dossier?.nom + ' ' + dossier?.prenom}</strong>
                  </h3>
                  <button
                    onClick={handleCloseModal}
                    type="button"
                    className="text-red-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>

                {isLoaded ? (
                  success ? (
                    <FormUrlDisplayer requestFormUrl={requestFormUrl} />
                  ) : (
                    <>
                      <IsLoaded />
                    </>
                  )
                ) : (
                  <div className="p-6 space-y-6">
                    <input
                      name="document"
                      onChange={(e) => setDocument(e.target.value)}
                      type="text"
                      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    />
                  </div>
                )}

                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b ">
                  {!isLoaded && (
                    <button
                      onClick={handleSubmit}
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Valider
                    </button>
                  )}
                  <button
                    onClick={handleCloseModal}
                    type="button"
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 "
                  >
                    Fermer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { Modal };
