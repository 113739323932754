import axios from 'axios';
const ApiUrl = process.env.REACT_APP_API_URL;

const CreateDocumentComplementaireRequest = async (idDossier, nomDocument) => {
  console.log(idDossier, nomDocument);
  try {
    const response = await axios.post(
      `${ApiUrl}/document/request`,
      {
        dossier: idDossier,
        nomDocument: nomDocument,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );

    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
const FetchDocumentComplementaireRequest = async (requesId) => {
  try {
    const response = await axios.get(`${ApiUrl}/document_requests/?requestId=${requesId}`);
    return response.data['hydra:member'];
  } catch (error) {
    return error.response.data;
  }
};

const SubmitDocumentComplementaireRequest = async (requestId, document) => {
  try {
    return await axios.post(
      `${ApiUrl}/document/request/submit`,
      {
        requestId: requestId,
        document: document,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
  } catch (error) {
    return error.response;
  }
};

const FetchDossierTempRequest = async (dossier) => {
  try {
    const response = await axios.get(`${ApiUrl}/document_requests?dossier=${dossier}&IsValide=1`);
    return response.data['hydra:member'];
  } catch (error) {
    return error.response.data;
  }
};

export {
  CreateDocumentComplementaireRequest,
  FetchDocumentComplementaireRequest,
  SubmitDocumentComplementaireRequest,
  FetchDossierTempRequest,
};
