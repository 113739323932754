import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../Context';
import { IndiceDossier } from '../../Services';

const SearchBayStatus = ({ typeProcedure }) => {
  const [currentStatus, setCurrentStatus] = useState(null);
  const { dossiers, fetchAllDossier, setDossiers } = useAppContext();

  useEffect(() => {
    fetchAllDossier(typeProcedure);
  }, [currentStatus]);

  const handleClick = (e) => {
    const status = e.target.value;
    const newCurrentStatus = IndiceDossier(status);
    setCurrentStatus(newCurrentStatus);
    setDossiers(dossiers.filter((dossier) => parseInt(dossier.statut) === newCurrentStatus));
  };

  return (
    <>
      <div className="flex flex-row justify-center items-center space-x-2 my-2">
        <input
          type="button"
          onClick={handleClick}
          value="Nouveau"
          className="hover:cursor-pointer hover:bg-blue-400 focus:bg-blue-400 focus:text-white focus:border-2 focus:border-blue-400 hover:text-white px-2 py-1.5 text-xs font-semibold text-gray-100 bg-blue-600 border border-gray-200 rounded-lg "
        />
        <input
          type="button"
          onClick={handleClick}
          value="En cours"
          className="hover:cursor-pointer hover:bg-blue-400 focus:bg-blue-400 focus:text-white focus:border-2 focus:border-blue-400 hover:text-white px-2 py-1.5 text-xs font-semibold text-gray-100 bg-blue-600 border border-gray-200 rounded-lg "
        />
        <input
          type="button"
          onClick={handleClick}
          value="Complète"
          className="hover:cursor-pointer hover:bg-blue-400 focus:bg-blue-400 focus:text-white focus:border-2 focus:border-blue-400 hover:text-white px-2 py-1.5 text-xs font-semibold text-gray-100 bg-blue-600 border border-gray-200 rounded-lg "
        />
        <input
          type="button"
          onClick={handleClick}
          value="Échec"
          className="hover:cursor-pointer hover:bg-bg-blue-400 focus:bg-blue-400 focus:text-white focus:border-2 focus:border-blue-400 hover:text-white px-2 py-1.5 text-xs font-semibold text-gray-100 bg-blue-600 border border-gray-200 rounded-lg "
        />
      </div>
    </>
  );
};

export { SearchBayStatus };
