import React from 'react';
import { useAppContext } from '../../Context';

const SearchBar = ({ typeProcedure }) => {
  const { dossiers, fetchAllDossier, setDossiers } = useAppContext();
  const [searchTerm, setSearchTerm] = React.useState('');

  const handleChange = (event) => {
    setSearchTerm(event.target.value);

    const term = event.target.value;

    if (term === '') {
      fetchAllDossier(typeProcedure);
      return;
    }

    const filteredDossiers = dossiers.filter(
      (dossier) =>
        dossier.nom.toLowerCase().includes(term.toLowerCase()) ||
        dossier.prenom.toLowerCase().includes(term.toLowerCase()) ||
        dossier.email.toLowerCase().includes(term.toLowerCase()) ||
        dossier.numeroDossier.toLowerCase().includes(term.toLowerCase())
    );

    setDossiers(filteredDossiers);
  };

  return (
    <>
      <div className="max-w-[400px] min-w-[400px] px-6 ">
        <form>
          <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
            Search
          </label>
          <div className="relative">
            <input
              onChange={handleChange}
              type="search"
              id="default-search"
              className=" focus:border-none block  w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
              placeholder="Jhon Doe.."
              required
            />
          </div>
        </form>
      </div>
    </>
  );
};

export { SearchBar };
