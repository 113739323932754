import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppContext } from '../../Context';

const Logout = () => {
  const { setUserToken, userToken } = useAppContext();

  useEffect(() => {
    if (userToken) {
      setUserToken(null);
      localStorage.removeItem('email');
      localStorage.removeItem('password');
    }
  }, [setUserToken, userToken]);

  return <Navigate to="/admin/12345azertyuip" />;
};

export { Logout };
