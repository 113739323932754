import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getUserToken, getAllDossier, getOneDossier } from '../Services';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [userToken, setUserToken] = useState(null);
  const [dossiers, setDossiers] = useState([]);

  const fetchAllDossier = async (typeProcedure) => {
    const response = await getAllDossier(typeProcedure);
    setDossiers(response.data['hydra:member']);
  };

  const fetchDossier = async (id) => {
    const response = await getOneDossier(id);
    setDossiers(response.data);
  };

  const fetchUserToken = async () => {
    try {
      const email = localStorage.getItem('email');
      const password = localStorage.getItem('password');

      if (email !== null && password !== null) {
        const data = await getUserToken(JSON.parse(email), JSON.parse(password));
        setUserToken(data.token);
      }
    } catch (error) {
      console.error('Failed to get user token:', error);
    }
  };

  useEffect(() => {
    fetchUserToken();
  }, []);

  const value = useMemo(() => {
    return {
      fetchUserToken,
      userToken,
      dossiers,
      setDossiers,
      setUserToken,
      fetchAllDossier,
      fetchDossier,
    };
  }, [dossiers, userToken]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
