import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const CreateNewCase = async (data) => {
  const form = new FormData();
  for (let key in data) {
    form.append(key, data[key]);
  }

  try {
    return await axios.post(`${API_URL}/dossier`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    });
  } catch (error) {
    return error.response;
  }
};
export { CreateNewCase };
