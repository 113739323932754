const testimonials = [
  {
    body: 'Je n’ai qu’une seule chose à dire c’est : merci Mr Cissé. C’est un monsieur honnête et qui fait bien son travail.',
    author: {
      name: 'Abdel-Khalik MAZU',
      school: 'Université libre de Bruxelles',
      imageUrl: '/assets/icons/user.png  ',
    },
  },
  {
    body: "Je tiens à souligner l'expertise exceptionnelle de Mr Cissé ABDRAHAMANE concernant le système Campus France. Il a une compréhension remarquable du choix des universités et des filières, analysant en profondeur le parcours scolaire pour garantir une acceptation universitaire. Grâce à lui, j'ai reçu trois acceptations et j'ai pu choisir l'université qui me convenait le mieux. En plus de son expertise, Mr Cissé est constamment disponible, consacrant son temps pour préparer les candidats aux entretiens. De plus, grâce à son vaste réseau, il est capable d'aider ceux qui n'ont ni garants ni attestation d'hébergement pour la demande de visa. Pour preuve, malgré l'absence de garant et d'hébergement, je poursuis mes études en France depuis 2021 grâce à Mr Cissé. Avec rigueur, attention et dévouement, tout se passe merveilleusement bien avec Mr Cissé et son équipe.",
    author: {
      name: 'ABDOUL-RAHMANE Abdoul-Aziz',
      school: 'Université Claude Bernard de Lyon',
      imageUrl: '/assets/icons/user.png  ',
    },
  },
  {
    body: "J'ai été ravi par un service exceptionnel où l'engagement des leaders et du personnel a été remarquable. Tout au long de ma procédure et de mon voyage j'ai été traité comme un invité de marque, une expérience inoubliable que je recommande vivement à d'autres. Je tiens à exprimer ma sincère gratitude à Monsieur Abdou Rahmane Cissé pour son rôle essentiel dans cette expérience incroyable.",
    author: {
      name: 'KANKOU ABDOULAYE',
      school: 'Université de Bordeaux',
      imageUrl: '/assets/icons/user.png  ',
    },
  },
  {
    body: "En ce qui me concerne, la procédure s'est bien passée avec vous, honnêtement. Je vous remercie pour le travail effectué et pour notre collaboration pendant toute la durée de cette procédure. Je suis également reconnaissant pour le soutien que vous m'avez témoigné tout au long de la procédure.",
    author: {
      name: 'BAH BORO Tairou',
      school: 'Université de Rennes',
      imageUrl: '/assets/icons/user.png  ',
    },
  },
  {
    body: 'Il n’y a pas de mots justes pour qualifier un travail satisfaisant. Pour autant, Mr Cissé et ses collaborateurs sont d’une efficacité remarquable. Leur dévouement et leur motivation qu’ils vous apportent vous laisse sans voix. Je conseil fortement de vous rapprocher d’eux pour vos projets de mobilité internationale. ',
    author: {
      name: 'SAOUD TRAORE',
      school: 'Université de Bordeaux',
      imageUrl: '/assets/icons/user.png  ',
    },
  },
  {
    body: "Je suis ici pour témoigner du professionnalisme et la sollicitude dont ont fait preuve M. CISSE et son équipe tout au long de la procédure pour la poursuite d'étude en Belgique. M. CISSE est un homme intègre qui a d'abord à cœur la réussite de ses candidats.  Et pour y arriver il met à la disposition de chacun toutes les informations nécessaires pour mener à bien son projet de mobilité. Il oriente par rapport aux choix des filières et apporte une oreille attentive à toutes les préoccupations des candidats. Avec cette équipe de choc on a la garantie de présenter un projet de mobilité cohérent et d'obtenir facilement le visa. Je conseille vivement à toute personne voulant ayant un projet de voyage de faire confiance à M. CISSE et son équipe.",
    author: {
      name: 'Ayman ODJO',
      school: 'Université de Liège',
      imageUrl: '/assets/icons/user.png  ',
    },
  },
];

export function Testimonials() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-10">
        <div className="mx-auto max-w-xl text-center">
          <h2 className="text-3xl font-semibold text-[#DA4167] leading-8 tracking-tight">Témoignages</h2>
          <p className="mt-2 text-lg font-bold tracking-tight text-gray-900">
            Nous avons travaillé avec des milliers de personnes extraordinaires
          </p>
        </div>
        <div className="mx-auto flow-root max-w-2xl lg:mx-0 lg:max-w-none mt-10">
          <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
            {testimonials.map((testimonial) => (
              <div key={testimonial.author.school} className="pt-8 sm:inline-block sm:w-full sm:px-4">
                <figure className="rounded-2xl bg-gray-50 p-8 text-base leading-6 hover:scale-105 duration-300">
                  <blockquote className="text-gray-900 text-justify">
                    <p>{`“${testimonial.body}”`}</p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center gap-x-4">
                    <img className="h-10 w-10 rounded-full bg-gray-50" src={testimonial.author.imageUrl} alt="" />
                    <div>
                      <div className="font-semibold text-gray-900">{testimonial.author.name}</div>
                      <div className="">{testimonial.author.school}</div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
