import axios from 'axios';

const ApiUrl = process.env.REACT_APP_API_URL;

const getAllDossier = async (typeProcedure) => {
  try {
    return await axios.get(`${ApiUrl}/dossier_etudiants?typeProcedure=${typeProcedure}`);
  } catch (error) {
    return error.response.data;
  }
};
const getOneDossier = async (id) => {
  try {
    const response = await axios.get(`${ApiUrl}/dossier_etudiants/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateDossier = async (id, statut, commentaire) => {
  //corrected typo here
  try {
    return await axios.patch(
      `${ApiUrl}/dossier_etudiants/${id}`,
      {
        statut: statut,
        commentaires: commentaire, //corrected typo here
      },
      {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
        },
      }
    );
  } catch (error) {
    return error.response.data;
  }
};

export { getAllDossier, getOneDossier, updateDossier };
