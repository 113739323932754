import { Fragment, useState } from 'react';
import { Accordion, AccordionHeader, AccordionBody } from '@material-tailwind/react';

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${id === open ? 'rotate-180' : ''} h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}
export function CanadaFaqs() {
  const [open, setOpen] = useState(0);
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="pt-10 md:px-0 px-6 mb-20">
      <h3 className="text-center text-4xl text-[#DA4167] font-bold mb-6">FAQs</h3>
      <div className="max-w-4xl mx-auto p-5 border border-sky-600 rounded-xl">
        <Fragment>
          <div className="flex flex-col space-y-5">
            <div>
              <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                <AccordionHeader onClick={() => handleOpen(1)}>
                  Quelles sont les conditions pour aller étudier au Canada?
                </AccordionHeader>
                <hr />
                <AccordionBody>
                  <p className="text-lg">
                    Pour étudier au Canada, vous devez respecter la loi, ne pas avoir de casier judiciaire et ne pas
                    être un risque pour la sécurité canadienne. Vous devrez peut-être fournir un certificat de police.
                  </p>
                </AccordionBody>
              </Accordion>
            </div>
            <div>
              <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                <AccordionHeader onClick={() => handleOpen(2)}>Quel âge pour aller étudier au Canada?</AccordionHeader>
                <hr />
                <AccordionBody>
                  <p className="text-lg">
                    Note : Au-delà des critères d'admission, vous n'avez pas besoin de posséder des compétences
                    particulières ou de l'expérience en lien avec une formation pour être admissible. Vous devez avoir
                    17 ans à votre arrivée au Québec. Il n'y a aucune limite d'âge pour étudier en formation
                    professionnelle.
                  </p>
                </AccordionBody>
              </Accordion>
            </div>
            <div>
              <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
                <AccordionHeader onClick={() => handleOpen(3)}>
                  Quel est le salaire d'un étudiant étranger au Canada ?
                </AccordionHeader>
                <hr />
                <AccordionBody>
                  <p className="text-lg">
                    Le salaire minimum varie selon les provinces et les territoires et se situe entre 13 $ à 16 $
                    l'heure.
                  </p>
                </AccordionBody>
              </Accordion>
            </div>
          </div>
        </Fragment>
      </div>
    </div>
  );
}
