const IndiceDossier = (value) => {
  switch (value) {
    case 'Nouveau':
      return 1;
    case 'En cours':
      return 2;
    case 'Complète':
      return 3;
    case 'Échec':
      return 4;
    default:
      return 4;
  }
};

export { IndiceDossier };
