import React from 'react';

const IsLoaded = () => {
  return (
    <>
      <div className="flex justify-center items-center h-96">
        <div className="animate-spin flex justify-center items-center text-xs rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900">
          <p>Veuillez patienter...</p>
        </div>
      </div>
    </>
  );
};

export { IsLoaded };
