import React from 'react';

export function Blog() {
  return (
    <div className="flex flex-col space-y-5 justify-center items-center h-screen">
      <h3 className="text-4xl">Blog bientôt disponible</h3>
      <a className="text-[#2196F3] underline" href="/">
        Retour à l'acceuil
      </a>
    </div>
  );
}
