const EmailValidator = (email) => {
  let formErrors = {};

  // simple email validation
  if (!email || !/\S+@\S+\.\S+/.test(email)) {
    formErrors.email = 'Adresse email invalide';
  }

  return { isValid: Object.keys(formErrors).length === 0, errors: formErrors };
};

export default EmailValidator;
