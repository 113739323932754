import React from 'react';

const TypeProcedureDisplayer = ({ typeProcedure }) => {
  switch (parseInt(typeProcedure)) {
    case 1:
      return <p>France</p>;
    case 2:
      return <p>Canada</p>;
    case 3:
      return <p>Belgique</p>;
    default:
      return <p></p>;
  }
};

export default TypeProcedureDisplayer;
