import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/tailwind.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { AppProvider } from './Context';

const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize('G-EFJLP25V72');

root.render(
  <BrowserRouter>
    <AppProvider>
      <App />
    </AppProvider>
  </BrowserRouter>
);
