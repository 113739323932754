import axios from 'axios';
const ApiUrl = process.env.REACT_APP_API_URL;

const getUserToken = async (username, password) => {
  try {
    const response = await axios.post(`${ApiUrl}/login_check`, {
      username: username,
      password: password,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Create new user
const createUser = async ({ email, password }) => {
  try {
    localStorage.setItem('email', JSON.stringify(email));
    localStorage.setItem('password', JSON.stringify(password));
    const response = await axios.post(`${ApiUrl}/register`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getUserToken, createUser };
