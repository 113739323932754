import React, { useEffect, useState } from 'react';
import { TableCellBe } from './TableCells';
import { useAppContext } from '../Context';
import { IsLoaded } from '../components';

const typeProcedure = 3;

export function DashboardBe() {
  const { fetchAllDossier } = useAppContext();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
    fetchAllDossier(typeProcedure);
    setIsLoaded(false);
  }, []);

  return (
    <div className="w-full">
      {isLoaded ? (
        <IsLoaded />
      ) : (
        <div className="w-full mt-8">
          <TableCellBe typeProcedure={typeProcedure} />
        </div>
      )}
    </div>
  );
}
