import React from 'react';
import { useState } from 'react';
import EmailValidator from '../../Services/emailValidator';
import { useAppContext } from '../../Context';
import { getUserToken } from '../../Services';
import { Navigate } from 'react-router-dom';

const LoginComponent = () => {
  const { setUserToken } = useAppContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState(null);
  const [isRequesting, setIsRequesting] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsRequesting(true);
    const validation = EmailValidator(email);
    if (!validation.isValid) {
      setErrorEmail(validation.errors.email);
    } else {
      try {
        localStorage.setItem('email', JSON.stringify(email));
        localStorage.setItem('password', JSON.stringify(password));
        const data = await getUserToken(email, password);

        //Set user token
        setUserToken(data.token);

        //Redirect to admin page
        return <Navigate to="/dashboard-france" />;
      } catch (e) {
        console.log(e);
      } finally {
        setIsRequesting(false);
      }
    }
  };

  return (
    <>
      {isRequesting ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-white opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white py-2 px-5  flex items-center flex-col">
            <div className="loader-dots block relative w-20 h-5 mt-2">
              <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-primary-600">Veuillez patienter...</div>
            </div>
          </div>
        </div>
      ) : (
        <section className="bg-gray-50">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                  Connexion Administateur
                </h1>
                <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="name@company.com"
                      required=""
                    />
                    {errorEmail && <p className="text-red-500 text-xs italic">{errorEmail}</p>}
                  </div>
                  <div>
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
                      Mot de passe
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                      required=""
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="remember"
                          aria-describedby="remember"
                          type="checkbox"
                          className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 "
                          required=""
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">
                          Se rappeler de moi
                        </label>
                      </div>
                    </div>
                    <a href="/" className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">
                      Mot de passe oublié ?
                    </a>
                  </div>
                  <button
                    type="submit"
                    className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    Se connecter
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export { LoginComponent };
