import { Fragment, useState } from 'react';
import { Accordion, AccordionHeader, AccordionBody } from '@material-tailwind/react';

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${id === open ? 'rotate-180' : ''} h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}
export function FranceFaqs() {
  const [open, setOpen] = useState(0);
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="pt-10 md:px-0 px-6 mb-20">
      <h3 className="text-center text-4xl text-[#DA4167] font-bold mb-6">FAQs</h3>
      <div className="max-w-4xl mx-auto p-5 border border-sky-600 rounded-xl">
        <Fragment>
          <div className="flex flex-col space-y-5">
            <div>
              <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                <AccordionHeader onClick={() => handleOpen(1)}>
                  Quel sont les conditions pour étudier en France ?
                </AccordionHeader>
                <hr />
                <AccordionBody>
                  <p className="text-lg">
                    Pour venir étudier, il vous faut simplement être en possession de votre passeport ou tout titre
                    d'identité en cours de validité, et être inscrit dans un établissement pour suivre, au titre de
                    votre activité principale, des études ou une formation professionnelle.
                  </p>
                </AccordionBody>
              </Accordion>
            </div>
            <div>
              <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                <AccordionHeader onClick={() => handleOpen(2)}>
                  Quel est l'âge limite pour étudier en France ?
                </AccordionHeader>
                <hr />
                <AccordionBody>
                  <p className="text-lg">
                    L'âge limite de 28 ans peut-être reculé dans certains cas : service national, longue maladie ou
                    maternité, études spéciales (report d'un à quatre ans pour les étudiants en études doctorales).
                  </p>
                </AccordionBody>
              </Accordion>
            </div>
          </div>
        </Fragment>
      </div>
    </div>
  );
}
